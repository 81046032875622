import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import Popover from '@react/react-spectrum/Popover';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import { ActionButton, ProgressCircle, SearchField, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import Highlighter from 'react-highlight-words';
import { groupBy } from 'lodash';
import styles from '../Theme/Styles/styles.module.css';
import { MoreActionsCombo } from '../Theme/Icons/Icons';
import localization from '../Language/language';
import PDCHighlighter from './PDCHighlighter'
import DeleteCustomPdcTrigger from './DeleteCustomPDCTrigger';
import { useCustomPdc } from '../hooks/useCustomPdc';
import { useFavoritePdc } from '../hooks/useFavoritePdc';
import HeartFilled from '../Icons/HeartFilled';
import HeartEmpty from '../Icons/HeartEmpty';


declare const window: any;

interface IProps {
  pdcList: any;
  callBackEvent: (eventType: string, eventData: object) => void;
  contextData: any;
  pdcTheme: string;
  favPdcList: any;
  agentId: string;
  pdcQueueName: string;
  customPdcList: any,
  pdcBaseURL: string,
  categoriesList: any
}
/* 
  istanbul ignore next
*/
const PredefinedContentBody = (props: IProps) => {
  const {
    pdcList: pdcApiData = [],
    contextData: { language = 'en', conversationClosed = false, agentFeatureTags, pdcBaseURL },
    pdcTheme = 'light',
    favPdcList: favPdcListProps,
    agentId,
    pdcQueueName,
    customPdcList: customPdcListProps,
    categoriesList = []
  } = props;

  const localizeStrings = localization[language]
  const [pdcList, setPdcList] = useState<any>([]);
  const [showTitlesOnly, setTitlesOnly] = useState<boolean>(false);
  const [searchPdc, setSearchPdc] = useState<string>('');
  const [pdcLoading, setPdcLoading] = useState(false);
  const [toggleActionsIcon, setToggleActionsIcon] = useState(true);
  const { customPdcList, createCustomPdc, deleteCustomPdc } = useCustomPdc(customPdcListProps, agentId, pdcBaseURL)
  const { favPdcList, createFavorites, removeFavorites } = useFavoritePdc(favPdcListProps, pdcQueueName, agentId)

  const groupByCategory = (data: any) => {
    return sortBySequenceNumber(groupBy(data, pdc => pdc.category));
  };
  const sortBySequenceNumber = (pdcList: any) => {
    Object.values(pdcList).forEach((messages: any) => {
      messages.sort((a, b) => {
        const seqA = a.titleSequence ? a.titleSequence : Infinity;
        const seqB = b.titleSequence ? b.titleSequence : Infinity;
        return seqA - seqB;
      });
    });
    return pdcList
  }

  const formatPDCList = useMemo(() => {

    let updatedKeys = [...Object.keys(pdcList).sort()]
    let updatedList = { ...pdcList };
    if (agentFeatureTags.pdcFavorites === 'true') {
      updatedKeys = ['favorites', 'custom', ...updatedKeys];
      updatedList = { ...updatedList, favorites: favPdcList, custom: customPdcList || [] };
    }
    return { updatedKeys, updatedList }
  }, [pdcList, favPdcList, agentFeatureTags.pdcFavorites, customPdcList])

  const { updatedKeys, updatedList } = formatPDCList;

  /* 
    istanbul ignore next
  */
  useEffect(() => {
    window.dunamis_analytics = window.dunamis_analytics || {};
    if (typeof window.dunamis_analytics.sendPDCEvents === 'function') {
      // dunamis render PDC event
      window.dunamis_analytics.sendPDCEvents({
        subType: 'title',
      });
    }
  }, []);

  useEffect(() => {
    const getPdcList = async () => {
      setPdcList(groupByCategory(pdcApiData));
    };
    getPdcList();
  }, [pdcApiData]);


  const pdcClass = styles[`pdc-messages-${pdcTheme}`];
  const pdcAccordionClass = styles[`pdc-accordion-item-${pdcTheme}`];
  const pdcThemeClass = styles[`pdc-theme-${pdcTheme}`];
  const accordionWrapperClass = styles[`pdc-accordion-wrapper-${pdcTheme}`];

  /* 
    istanbul ignore next
  */
  const formatHotKey = (hotKey: string, category: string, messageId: any) => {
    if (category === 'favorites') {
      const currItem = pdcApiData?.find((pdc: any) => pdc?.messageId === messageId);
      const hKey = currItem?.winHotKey;
      if (hKey) {
        const formattedHotkey = hKey.toUpperCase().split('+').join(' + ');
        return <Highlighter searchWords={[searchPdc]} textToHighlight={formattedHotkey} autoEscape />
      }
    }
    if (hotKey) {
      const formattedHotkey = hotKey.toUpperCase().split('+').join(' + ');
      return <Highlighter searchWords={[searchPdc]} textToHighlight={formattedHotkey} autoEscape />
    }
    return hotKey
  }

  /* 
    istanbul ignore next
  */
  useEffect(() => {
    const filterPdc = () => {
      const filteredPdcAfterSearch = pdcApiData
        .map((item: { category: string; title: string; message: string, winHotKey: string }) => {
          const isCategoryMatched =
            item.category && item.category.toUpperCase().indexOf(searchPdc.toUpperCase()) !== -1;

          const isTitleMatched =
            item.category && item.title.toUpperCase().indexOf(searchPdc.toUpperCase()) !== -1;

          const isWinHotKeyMatched =
            !showTitlesOnly &&
            item.category && item.winHotKey &&
            item.winHotKey.toUpperCase().split('+').join(' + ').indexOf(searchPdc.toUpperCase()) !== -1;

          const isMessageMatched =
            !showTitlesOnly &&
            item.category &&
            item.message.toUpperCase().indexOf(searchPdc.toUpperCase()) !== -1;

          if (isCategoryMatched || isTitleMatched || isMessageMatched || isWinHotKeyMatched) {
            return item;
          }
        })
        .filter((pdcItem: any) => pdcItem);
      console.log(filteredPdcAfterSearch, 'after search');
      setPdcList(groupByCategory(filteredPdcAfterSearch));
    };
    if (searchPdc.length > 0) filterPdc();
    else setPdcList(groupByCategory(pdcApiData));
    setPdcLoading(false);
  }, [searchPdc]);

  /* 
    istanbul ignore next
  */
  const onMessageClick = (message: string, _category: string) => {
    console.log('pdc-message-selected', message);
    if (typeof window.dunamis_analytics.sendPDCEvents === 'function') {
      // dunamis search PDC
      searchPdc !== '' &&
        window.dunamis_analytics.sendPDCEvents({
          subType: 'search',
          type: 'click',
          value: searchPdc,
        });
      // dunamis click PDC message
      window.dunamis_analytics.sendPDCEvents({
        subType: 'predefined-text',
        type: 'click',
        value: message,
      });
    }
    !conversationClosed && props.callBackEvent('closePdc', { message });
  };

  /* 
    istanbul ignore next
  */
  const onCategoryClick = (category: string) => {
    console.log('pdc-category-selected', category);
    if (typeof window.dunamis_analytics.sendPDCEvents === 'function') {
      // dunamis render PDC event
      window.dunamis_analytics.sendPDCEvents({
        subType: 'title',
        type: 'click',
        value: category,
      });
    }
  };

  const pdcSelectedIndexList = () => {
    console.log(pdcList, favPdcList, customPdcList, Object.keys(pdcList))
    let startIdx: number;
    const isSearchInFav = favPdcList?.find((fav: any) => fav?.title?.toLowerCase()?.includes(searchPdc?.toLowerCase()) || fav?.message?.toLowerCase()?.includes(searchPdc?.toLowerCase()))
    const isSearchInCustom = customPdcList?.find((custom: any) => custom?.title?.toLowerCase()?.includes(searchPdc?.toLowerCase()) || custom?.message?.toLowerCase()?.includes(searchPdc?.toLowerCase()))

    if (isSearchInFav) startIdx = 0;
    else if (isSearchInCustom) startIdx = 1;
    else startIdx = 2


    if (searchPdc.length > 0) return Object.keys(pdcList).map((_, index) => index + startIdx);
    else return [];
  };

  const getSelectionStatus = (title: string, message: string): string => favPdcList.some((fav: any) => fav.title === title && fav.message === message) ? "favorited" : 'not-favorited'

  const toggleSelection = (title: string, message: string): boolean => {
    const filteredList = favPdcList.filter((fav: any) => fav.title === title && fav.message === message)
    return filteredList.length > 0
  }

  const showPdcFavoritesIcon = agentFeatureTags?.pdcFavorites === 'true'

  const getCategoryTitle: any = (category: string) => {
    if (category === 'custom' && customPdcList.length) {
      return `${category} (${customPdcList?.length})`
    }
    if (category === 'favorites' && favPdcList.length) {
      return `${category} (${favPdcList?.length})`
    }

    return category
  }

  const getEmptyText: any = (category: string) => {
    if (category === 'favorites') {
      return <span>{localizeStrings['empty_favorites']}</span>
    }
    else if (category === 'custom') {
      return <span>{localizeStrings['empty_custom']}</span>
    }

    return ''
  }
  /* 
    istanbul ignore next
  */
  return (
    <div className={pdcThemeClass}>
      <div className={styles['pdc-search']}>
        <SearchField
          width='90%'
          placeholder={localizeStrings['search_for_phrase']}
          onClear={() => setSearchPdc('')}
          onChange={setSearchPdc}
          onInput={() => setPdcLoading(true)}
          value={searchPdc}
        />
        <OverlayTrigger
          trigger="click"
          role="tooltip"
          placement="bottom"
          crossOffset={-80}
          onShow={() => {
            setToggleActionsIcon(!toggleActionsIcon);
          }}
          onHide={() => {
            setToggleActionsIcon(!toggleActionsIcon);
          }}>
          <div className={styles['pdc-titles-popover']}>
            <MoreActionsCombo toggleActionsIcon={toggleActionsIcon} />
          </div>
          <Popover variant="default" role="tooltip" className={styles['pdc-popover']}>
            <div
              className={styles['pdc-popover-message']}
              onClick={() => setTitlesOnly(!showTitlesOnly)}>
              <div className={styles['pdc-popover-message-title']}>
                {!showTitlesOnly
                  ? localizeStrings['show_titles_only']
                  : localizeStrings['preview_text']}
              </div>
              <div className={styles['pdc-popover-message-body']}>
                {!showTitlesOnly
                  ? localizeStrings['show_titles_only_message']
                  : localizeStrings['preview_text_message']}
              </div>
            </div>
          </Popover>
        </OverlayTrigger>
      </div>

      {!pdcLoading ? (
        <React.Fragment>
          <Accordion
            className={accordionWrapperClass}
            multiselectable
            defaultSelectedIndex={pdcSelectedIndexList()}>
            {updatedKeys
              .map(category => {
                return (
                  <AccordionItem
                    selected
                    onClick={() => onCategoryClick(category)}
                    header={
                      <PDCHighlighter
                        category={getCategoryTitle(category)}
                        searchPdc={searchPdc}
                        list={categoriesList}
                        customPdcLength={customPdcList?.length}
                        apiCallbackHandler={createCustomPdc}
                        localizeStrings={localizeStrings}
                      />
                    }
                    className={pdcAccordionClass}
                    key={`pdc-accordion-item-${category}`}>
                    {updatedList[category]?.length ?
                      updatedList[category].map(
                        ({
                          message = '',
                          title = '',
                          messageId = '',
                          category: messageCategory = '',
                          winHotKey = ''
                        }: any) => (
                          <div
                            className={pdcClass}
                            key={`pdc-content-${messageId || message}`}
                            onClick={e => {
                              e.stopPropagation();
                              onMessageClick(message, messageCategory);
                            }}>
                            <div className={styles['pdc-hot-key']}>{formatHotKey(winHotKey, category, messageId)}</div>
                            <div className={styles['pdc-message-title']}>
                              <Highlighter searchWords={[searchPdc]} textToHighlight={title} autoEscape />
                            </div>
                            {!showTitlesOnly && (
                              <div className={styles['pdc-title-with-message']}>
                                <div>
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[searchPdc]}
                                    autoEscape
                                    textToHighlight={message}
                                  />
                                </div>
                              </div>
                            )}
                            {showPdcFavoritesIcon ? <div onClick={(e) => {
                              e.stopPropagation()
                              toggleSelection(title, message) === true ? removeFavorites({ title, message, messageId }, pdcBaseURL) : createFavorites({ title, message, messageId }, pdcBaseURL)

                            }}
                              className={styles['favorite-icon-container']}
                              style={category === 'custom' ? { padding: '0px', paddingTop: '12px' } : {}}
                            >
                              {category === 'custom' ? <div onClick={e => {
                                e.stopPropagation()
                              }}>
                                <DeleteCustomPdcTrigger apiCallbackHandler={deleteCustomPdc} pdcContent={{ message, title, messageId }} localizeStrings={localizeStrings} />
                              </div> : null}
                              {category !== 'custom' ?
                                <div
                                  className={
                                    `${styles[`favorite-icon-container-${getSelectionStatus(title, message)}`]} 
                                 ${styles['favorite-visibility']}
                                `
                                  }
                                >
                                  {favPdcList?.length >= 8 && toggleSelection(title, message) === false ?
                                    <TooltipTrigger delay={0}>
                                      <ActionButton UNSAFE_style={{ background: 'transparent', border: 'none', paddingBottom: '10px' }}>
                                        <HeartEmpty />
                                      </ActionButton>
                                      <Tooltip>{localizeStrings['maximum_favorite']}</Tooltip>
                                    </TooltipTrigger>
                                    :
                                    toggleSelection(title, message) === false ? <HeartEmpty /> : <HeartFilled size='S' />
                                  }

                                </div> : null
                              }

                            </div> : null}
                          </div>
                        ),
                      ) : category === 'favorites' || category === 'custom' ? getEmptyText(category) : null}
                  </AccordionItem>
                );
              })}
          </Accordion>
        </React.Fragment>
      ) : (
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      )
      }
    </div >
  );
};

export default React.memo(PredefinedContentBody);


