/* eslint-disable @typescript-eslint/no-var-requires */
require('dotenv').config()

if (process.env.REACT_APP_NODE_ENV === 'prod') {
  //US EAST
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_PROD_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_PROD_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-prod.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
      'https://adobe.okta.com/app/amazon_aws/exk1n1v396zA7YlKo0h8/sso/saml?RelayState=https://us-east-1.console.aws.amazon.com/connect/federate/9bdd009d-44c1-40e1-8dd1-92951b457d8e?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-east-1.amazonaws.com',
    CCP_CH_URL: 'https://convservice.adobe.io',
    CCP_API_END_POINT: 'https://convservice.adobe.io',
    AWS_REGION: 'us-east-1',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-ent.crm.dynamics.com/main.aspx?appid=f2e74f34-7119-ea11-a811-000d3a5936c5&forceUCI=1&pagetype=webresource&webresourceName=ent_%2fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-ent.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-ent.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-prod.my.connect.aws',
    PDC_TRANSLATE_URL: 'https://convservice.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    PDC_TRANSLATE_URL: 'https://convservice.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc.adobe.io',
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru.adobe.io',
    FG_ENDPOINT_RTS: 'https://p13n.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
} else if (process.env.REACT_APP_NODE_ENV === 'qe2') {
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_QE2_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_STAGE_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-qe.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
      'https://adobe-stage.okta.com/app/amazon_aws/exk1jfqy1qr0dLKP81d8/sso/saml?RelayState=https://us-west-2.console.aws.amazon.com/connect/federate/0429b4ef-c9a4-4392-a4eb-f04ecc9034d8?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-west-2.amazonaws.com',

    CCP_CH_URL: 'https://convservice-qe.adobe.io',
    CCP_API_END_POINT: 'https://convservice-qe.adobe.io',
    AWS_REGION: 'us-west-2',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm-qe.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-estg.crm.dynamics.com/main.aspx?appid=2c849ca1-2117-ea11-a811-000d3a593e5e&forceUCI=1&pagetype=webresource&webresourceName=ent_%2Fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',

    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm-qe.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.stage.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    // CIP
    CIP_DOMAIN: 'https://cip-qe.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc-dev.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc-dev.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-qe.my.connect.aws',
    //PDC Favorites, Custom and Geofluency
    PDC_TRANSLATE_URL: 'https://convservice-qe.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-dev.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    PDC_TRANSLATE_URL: 'https://convservice-qe.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-dev.adobe.io',
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware-qa.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru-qe.adobe.io',
    FG_ENDPOINT_RTS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor-qe.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
} else if (process.env.REACT_APP_NODE_ENV === 'stage2') {
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_STAGE2_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_PREVIEW_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-stg-ue1.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
      'https://adobe.oktapreview.com/app/amazon_aws/exk14wvncm0onwoow0h8/sso/saml?RelayState=https://us-east-1.console.aws.amazon.com/connect/federate/853e1479-e0a6-4d0a-aa9d-cf1434d738da?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-east-1.amazonaws.com',
    CCP_CH_URL: 'https://convservice-stage.adobe.io',
    CCP_API_END_POINT: 'https://convservice-stage.adobe.io',
    AWS_REGION: 'us-east-1',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm-stage.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-estg.crm.dynamics.com/main.aspx?appid=2c849ca1-2117-ea11-a811-000d3a593e5e&forceUCI=1&pagetype=webresource&webresourceName=ent_%2Fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm-stage.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.stage.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip-stage.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc-stage.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc-stage.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-stg-ue1.my.connect.aws',
    PDC_TRANSLATE_URL: 'https://convservice-stage.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-stage.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    PDC_TRANSLATE_URL: 'https://convservice-stage.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-stage.adobe.io',
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware-stage.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru-stage.adobe.io',
    FG_ENDPOINT_RTS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor-stage.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
} else if (process.env.REACT_APP_NODE_ENV === 'stage3') {
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_STAGE_UW2_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_PREVIEW_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-stg-uw2.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
    'https://adobe.oktapreview.com/app/amazon_aws/exk1vla5cus0GpDbi0h8/sso/saml?RelayState=https://us-west-2.console.aws.amazon.com/connect/federate/853e1479-e0a6-4d0a-aa9d-cf1434d738da?destination=%2Fconnect%2Fccp-v2https://adobe.oktapreview.com/app/amazon_aws/exk1vla5cus0GpDbi0h8/sso/saml?RelayState=https://us-west-2.console.aws.amazon.com/connect/federate/853e1479-e0a6-4d0a-aa9d-cf1434d738da?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-west-2.amazonaws.com',
    CCP_CH_URL: 'https://convservice-stage-uw2.adobe.io',
    CCP_API_END_POINT: 'https://convservice-stage-uw2.adobe.io',
    AWS_REGION: 'us-west-2',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm-stage.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-estg.crm.dynamics.com/main.aspx?appid=2c849ca1-2117-ea11-a811-000d3a593e5e&forceUCI=1&pagetype=webresource&webresourceName=ent_%2Fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm-stage-uw2.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.stage.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip-stage.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc-stage.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc-stage.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-stg-uw2.my.connect.aws',
    PDC_TRANSLATE_URL: 'https://convservice-stage-uw2.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-stage.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware-stage.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    PERSONALIZATION_DOMAIN: ' https://oc-ru-stage.adobe.io',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    FG_ENDPOINT_RTS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor-stage.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
} else if (process.env.REACT_APP_NODE_ENV === 'prod3') {
  //ACGR US WEST
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_PROD_UW2_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_PROD_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-prod-uw2.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
    'https://adobe.okta.com/app/amazon_aws/exk1u811bktWKhEQT0h8/sso/saml?RelayState=https://us-west-2.console.aws.amazon.com/connect/federate/9bdd009d-44c1-40e1-8dd1-92951b457d8e?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-west-2.amazonaws.com',
    CCP_CH_URL: 'https://convservice-uw2.adobe.io',
    CCP_API_END_POINT: 'https://convservice-uw2.adobe.io',
    AWS_REGION: 'us-west-2',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-ent.crm.dynamics.com/main.aspx?appid=f2e74f34-7119-ea11-a811-000d3a5936c5&forceUCI=1&pagetype=webresource&webresourceName=ent_%2fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-ent.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-ent.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-prod-uw2.my.connect.aws/ccp-v2',
    PDC_TRANSLATE_URL: 'https://convservice-uw2.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru.adobe.io',
    FG_ENDPOINT_RTS: 'https://p13n.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
} else if (process.env.REACT_APP_NODE_ENV === 'alpha2') {
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_ALPHA2_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_STAGE_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-alpha-ue1.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
      'https://adobe-stage.okta.com/app/amazon_aws/exk1kloukzh0gwiuW1d8/sso/saml?RelayState=https://us-east-1.console.aws.amazon.com/connect/federate/fc1271fb-9b24-4bf7-b094-fbd42efe4b22?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-east-1.amazonaws.com',
    CCP_CH_URL: 'https://convservice-prestage.adobe.io',
    CCP_API_END_POINT: 'https://convservice-prestage.adobe.io',
    AWS_REGION: 'us-east-1',
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm-prestage.adobe.io',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-estg.crm.dynamics.com/main.aspx?appid=2c849ca1-2117-ea11-a811-000d3a593e5e&forceUCI=1&pagetype=webresource&webresourceName=ent_%2Fcustomersearch.html',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm-prestage.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.stage.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip-prestage.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc-prestage.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc-prestage.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-alpha-ue1.my.connect.aws',
    PDC_TRANSLATE_URL: 'https://convservice-prestage.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-prestage.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware-stage.adobe.io/api/v1/gen-ai',
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru-stage.adobe.io',
    FG_ENDPOINT_RTS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor-qe.adobe.io/api/v1',
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  } 
} else {
  module.exports = {
    REACT_APP_PORT: process.env.REACT_APP_PORT,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_DEV_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER_ID: process.env.REACT_APP_STAGE_OKTA_ISSUER_ID,
    REACT_APP_OKTA_TESTING: process.env.REACT_APP_OKTA_TESTING || false,
    CCP_URL: 'https://adb-oac-dev.my.connect.aws/ccp-v2',
    CCP_LOGIN_URL:
      'https://adobe-stage.okta.com/app/amazon_aws/exk1jbpqhnfzMF0A91d8/sso/saml?RelayState=https://us-west-2.console.aws.amazon.com/connect/federate/cd824eb7-de3a-452d-95f2-c97c2e5bb442?destination=%2Fconnect%2Fccp-v2',
    CONNECT_API_URL: 'https://connect.us-west-2.amazonaws.com',
    CCP_CH_URL: 'https://convservice-dev.adobe.io',
    CCP_API_END_POINT: 'https://convservice-dev.adobe.io',
    AWS_REGION: 'us-west-2',
    // CCP_CH_URL:'http://localhost:3000/api/getConversationHistory/customer'
    // ECM
    REACT_APP_ECM_BASE_URL: 'https://oac-ecm-dev.adobe.io',
    REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=ent_organizationcontact&id=',
    REACT_APP_ENT_CASE_DEEPLINK_URL: 'https://adobe-estg.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ECM_SEARCH_CUSTOMER_URL : 'https://adobe-estg.crm.dynamics.com/main.aspx?appid=2c849ca1-2117-ea11-a811-000d3a593e5e&forceUCI=1&pagetype=webresource&webresourceName=ent_%2Fcustomersearch.html',
    // CCM
    REACT_APP_CCM_BASE_URL: 'https://oac-ccm-dev.adobe.io',
    REACT_APP_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=contact&id=',
    REACT_APP_CASE_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&pagetype=entityrecord&etn=incident&id=',
    REACT_APP_ADD_CUSTOMER_DEEPLINK_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=CustomerOne&newWindow=true&pagetype=entityrecord&etn=contact',
    REACT_APP_CCM_UCI_DYNAMIC_URL: 'https://adobe-istg.crm.dynamics.com/main.aspx?app=QuickApp&navbar=off&pagetype=entityrecord&etn=incident',
    HENDRIX_DOMAIN: 'https://hendrix360.stage.adobe.com',
    CM_DOMAIN: 'https://casemgmt-qe.msgbotcon.adobe.com',
    CIP_DOMAIN: 'https://cip-dev.adobe.io',
    PDC_QUERY_URL: 'https://oac-pdc-dev.adobe.io/api/v1/pdc/read/query/',
    PDC_HOTKEYS_URL: 'https://oac-pdc-dev.adobe.io/api/v1/pdc/read/hotkeys',
    CCP_LOGOUT_URL: 'https://adb-oac-dev.my.connect.aws',
    PDC_TRANSLATE_URL: 'https://convservice-dev.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-dev.adobe.io',
    VPN_SET_INTERVAL_TIME: 30000,
    VPN_SET_TIMEOUT_VALUE: 5000,
    PDC_TRANSLATE_URL: 'https://convservice-dev.adobe.io/api/translate/chat',
    PDC_BASE_URL: 'https://oac-pdc-dev.adobe.io',
    GENAI_MIDDLEWARE_URL: 'https://genai-core-middleware-qa.adobe.io/api/v1/gen-ai',// 'http://localhost:3001/api/v1/gen-ai'
    FG_ENDPOINT_CONVO_SUMMARY: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=conversationSummary&meta=false',
    FG_ENDPOINT_AA: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswers&meta=false',
    FG_ENDPOINT_AAFeedback: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=AdobeAnswersFlags&meta=true',
    FG_ENDPOINT_RU: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-ru-widget&meta=true',
    PERSONALIZATION_DOMAIN: 'https://oc-ru-dev.adobe.io',//'http://localhost:8082'// 
    FG_ENDPOINT_RTS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?meta=true',
    ENDPOINT_RTS_PROCESSOR: 'https://oc-rtcs-processor-dev.adobe.io/api/v1',//'http://localhost:8084/api/v1',// 
    FG_ENDPOINT_OAC_FEATURE_FLAGS: 'https://p13n-stage.adobe.io/fg/api/v3/feature?clientId=oac-feature-flags&meta=true',
  }
}
